<template>
  <v-dialog v-model="show" max-width="40%">
    <template v-slot:activator="{ on, attrs }">
      <v-icon v-bind="attrs" v-on="on" small class="mr-2" @click="editRole">
        mdi-pencil
      </v-icon>
      <v-icon v-bind="attrs" v-on="on" small @click="deleteRole">
        mdi-delete
      </v-icon>
    </template>
    <v-card>
      <v-card-title class="justify-center">
        {{ formTitle }}
      </v-card-title>
      <v-divider></v-divider>
      <v-progress-linear v-show="loading" indeterminate color="primary" background-opacity="0">
      </v-progress-linear>
      <v-card-text v-if="actionType === 'edit'">
        <v-form ref="editRoleForm" lazy-validation class="px-6">
          <v-row no-gutters>
            <v-col cols="6">
              <v-text-field v-model="role.name" label="Role Name" :rules="rules.nameRules" required>
              </v-text-field>
              <v-textarea outlined label="Description" @keyup.enter="addNewRole" v-model="role.description">
              </v-textarea>
            </v-col>
            <v-col cols="6" align-self="center">
              <v-card-title class="text-h6 black--text justify-center"> Permissions </v-card-title>
              <span class="px-5 black--text font-weight-bold">Group Permissions</span>
              <v-row no-gutters align="center" justify="center" class="pb-5">
                <v-col cols="3">
                  <v-checkbox v-model="role.permissions.orgGrp.read" label="Read" color="primary" hide-details>
                  </v-checkbox>
                </v-col>
                <v-col cols="3">
                  <v-checkbox v-model="role.permissions.orgGrp.write" label="Write" color="primary" hide-details>
                  </v-checkbox>
                </v-col>
                <v-col cols="3">
                  <v-checkbox v-model="role.permissions.orgGrp.delete" label="Delete" color="red" hide-details>
                  </v-checkbox>
                </v-col>
              </v-row>
              <span class="px-5 black--text font-weight-bold">Kiosk Permissions</span>
              <v-row no-gutters align="center" justify="center" class="pb-10">
                <v-col cols="3">
                  <v-checkbox v-model="role.permissions.kiosk.read" label="Read" color="primary" hide-details>
                  </v-checkbox>
                </v-col>
                <v-col cols="3">
                  <v-checkbox v-model="role.permissions.kiosk.write" label="Write" color="primary" hide-details>
                  </v-checkbox>
                </v-col>
                <v-col cols="3">
                  <v-checkbox v-model="role.permissions.kiosk.delete" label="Delete" color="red" hide-details>
                  </v-checkbox>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col align="end">
              <v-btn @click="show = false" color="error" dark large outlined width="175" class="mx-3">
                <v-icon left>mdi-close</v-icon>
                Close
              </v-btn>
              <v-btn @keyup.enter="updateRole" @click="updateRole" color="primary" dark large width="175">
                <v-icon left>mdi-pencil</v-icon>
                Edit
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions v-if="actionType === 'delete'">
        <v-spacer></v-spacer>
        <v-btn color="error" @click="show = !show" outlined width="175"><v-icon>mdi-close</v-icon>Close</v-btn>
        <v-btn color="primary" @click="confirmDeleteRole" width="175"><v-icon>mdi-delete</v-icon>Delete</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
  
<script>
export default {
  name: 'editAndDeleteRole',
  props: {
    roleIndex: Object,
  },
  data() {
    return {
      show: null,
      actionType: '',
      formTitle: '',
      loading: false,
      role: {
        name: '',
        description: '',
        id: null,
      },
      rules: {
        nameRules: [
          v => !!v || 'Role name is required',
        ],
        descriptionRules: [
          v => !!v || 'Description is required',
        ],
      }
    }
  },
  methods: {
    editRole() {
      this.formTitle = "Edit Role";
      this.actionType = 'edit';
      this.role = Object.assign({}, this.roleIndex);
      this.role.id = this.roleIndex.id;
    },
    deleteRole() {
      this.formTitle = 'Are you sure you want to delete this role?'
      this.actionType = 'delete';
    },
    updateRole() {
      if (this.$refs.editRoleForm.validate()) {
        this.loading = true;
        this.$store.dispatch('updateRole', this.role)
          .then(response => {
            this.loading = false;
            this.show = false;
            this.$store.dispatch('showSnackBar', {
              text: response,
              color: 'success'
            })
          }, error => {
            this.$store.dispatch('showSnackBar', {
              text: error,
              color: 'error'
            })
          })
      }
    },
    confirmDeleteRole() {
      this.loading = false;
      this.$store.dispatch('deleteRole', this.roleIndex.id)
        .then(response => {
          this.loading = false;
          this.show = false;
          this.$store.dispatch('showSnackBar', {
            text: response,
            color: 'success'
          })
        }, error => {
          this.$store.dispatch('showSnackBar', {
            text: error,
            color: 'error'
          })
        })
    }


  }
}
</script>
  