import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{attrs:{"fluid":""}},[_c(VCard,{staticClass:"px-3",attrs:{"elevation":"0"}},[_c(VCardTitle,{staticClass:"text-h4"},[_vm._v(" Role Management "),_c(VSpacer),(_vm.isSuperAdmin)?_c('addNewRole'):_vm._e()],1)],1),_c(VCard,{staticClass:"px-3 mt-3",attrs:{"elevation":"0"}},[_c(VCardTitle,[(_vm.userRoles.length <= 1)?_c('span',[_vm._v(_vm._s(_vm.userRoles.length)+" Role")]):_c('span',[_vm._v(_vm._s(_vm.userRoles.length)+" Roles")]),_c(VSpacer),_c(VTextField,{attrs:{"append-icon":"mdi-magnify","label":"Search User Role by Name","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c(VDataTable,{attrs:{"loading":_vm.loading,"headers":_vm.getHeaders,"items":_vm.userRole,"search":_vm.search},scopedSlots:_vm._u([{key:"item.name",fn:function({ item }){return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item.userCount",fn:function({ item }){return [_vm._v(" "+_vm._s(item.userCount)+" ")]}},{key:"item.dateCreated",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.dateCreated))+" ")]}},{key:"item.lastUpdated",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.lastUpdated))+" ")]}},{key:"item.description",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm._f("trimLength")(item.description))+" ")]}},{key:"item.actions",fn:function({ item }){return [(_vm.isSuperAdmin)?_c('editAndDeleteRole',{attrs:{"roleIndex":item}}):_vm._e()]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }